import { createReducer, on } from '@ngrx/store';
import { setMapPath } from './../actions/map-path.actions';

export const initialState = [];
 
const _MapPathReducer = createReducer(initialState,
  on(setMapPath, (state, action) => action.map) ,
);

export function MapPathReducer(state, action) {
    return _MapPathReducer(state, action);
}