<style>
    .coloring-book {
        padding: 0 15px;
    }
    .coloring-book svg {
        width: 100%;

        
    }
    .coloring-book svg * {
        cursor: pointer!important;
    }
</style>
<close-page></close-page>
<page-title [title]="'Kolorowanka'"></page-title>
<ng-container *ngIf="svgItems">
    <div class="popup-coloring-book" *ngIf="popup">
        <div class="items">
            <ng-container *ngFor="let item of svgItems; let i = index">
                <div (click)="changeSvg(i)" [className]="activeSvgIndex == i ? 'item active' : 'item'">
                    <img [src]="item.src" alt="kolorowanka" />
                </div>
            </ng-container>
        </div>
    </div>
    
    <div class="top-bar">
        <div class="tools">
            <div class="tool" [class]="{'active': activeTool === 'brush'}">
                <brush (click)="activeTool = 'brush'" [color]="activeTool === 'brush' ? '#ffffff' : '#495535'"></brush>
            </div>
            <div class="tool" [class]="{'active': activeTool === 'eraser'}">
                <eraser (click)="activeTool = 'eraser'" [color]="activeTool === 'eraser' ? '#ffffff' : '#495535'"></eraser>
            </div>
        </div>
    
        <div class="colors">
            <div class="color" [class]="{ 'active': color === activeColor }" *ngFor="let color of colors"
                [style]="{ 'background-color': '#' + color }" (click)="activeColor = color"></div>
        </div>
        <div class="btn-coloring" (click)="openPopup()">Wybierz kolorowanke</div>
    </div>
    
    <div class="coloring-book">
    </div>
</ng-container>