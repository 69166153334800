import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  map$:Observable<object>
  apiLoaded: Observable<boolean> ;

  mapOption: string = 'none';
  mapDefault: object;
  mapWyczolkowski: object;

  options: google.maps.MapOptions = {
    center: {lat: 53.5164313, lng: 18.1183883},
    zoom: 15
  };

  constructor(httpClient: HttpClient, private store: Store<{ map: object }>) {
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyALVQIVj6L6oS3l7missu1ZTrCOXaH3fwQ', 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );
    this.map$ = store.pipe(select('map'));
  }

  ngOnInit(): void {
    this.map$.subscribe(map => { this.mapDefault = map['mapa']; this.mapWyczolkowski = map['wyczolkowski'] } );
  }

  log = (val) => console.log(val);

  changeMap = option => {
    let old = this.mapOption;
    if ( option === old ) this.mapOption = 'none';
    else this.mapOption = option;
  }

  setMyStyles(item) {
    let styles = {
      'left': item.position_left + '%',
      'top': item.position_top + '%',
    };
    return styles;
  }

}
