<h4>{{ title }} {{ type }}</h4>
<div class="items">
    <div *ngFor="let image of data; let i=index" class="item">
        <img class="play" src="./assets/img/play-icon.png" alt="Wideo"  (click)="open(i)" *ngIf="type === 'video'">
        <img class="thumb" [src]="image.poster.sizes.small" (click)="open(i)" *ngIf="type === 'video'" />
        <img class="thumb" [src]="image.sizes.small" (click)="open(i)" *ngIf="type === 'image'" />
    </div>
</div>
<div class="modal" *ngIf="activeModal">
    <div class="container">
        <div class="close" (click)="close()">
            <img src="./assets/img/close.png" alt="Zamknij">
        </div>
        <div class="arrows">
            <img src="./assets/img/arrow.svg" class="arrow left" alt="poprzednie" (click)="prevGallery()">
            <img src="./assets/img/arrow.svg" class="arrow right" alt="Nastęne" (click)="nextGallery()">
        </div>
        <div class="image" *ngIf="type === 'image'">
            <img [src]="data[activeIndex].sizes.big" [srcset]="data[activeIndex].sizes.small + ' 640w, ' + data[activeIndex].sizes.medium + ' 1280w, ' + data[activeIndex].sizes.big + ' 1281w'" [alt]="data[activeIndex].alt">
        </div>
        <div class="video" *ngIf="type === 'video'">
            <iframe  [src]="(ytEmbed(data[activeIndex].src) | youtube)" frameborder="0"  allowfullscreen></iframe>
        </div>
        <div class="description">
            <h5>{{ data[activeIndex].name }}</h5>
            <p *ngIf="data[activeIndex].description">{{ data[activeIndex].description }}</p>
        </div>
    </div>
</div>