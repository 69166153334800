import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import axios from 'axios';

@Component({
  selector: 'app-coloring-book',
  templateUrl: './coloring-book.component.html',
  styleUrls: ['./coloring-book.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColoringBookComponent implements OnInit {
  gallery$:Observable<object>;

  constructor(private store: Store<{ gallery: Array<object> }>, private ngZone:NgZone) {
    this.gallery$ = store.pipe(select('gallery'));
    store.select('gallery').subscribe((gallery) => this.setSvgItems(gallery))

    window.onresize = (e) => {
      this.ngZone.run(() => {
        var mobileOrDesktop = 'desktop';
        if(window.innerWidth < 768) {
          mobileOrDesktop = 'mobile'
        }
        if(mobileOrDesktop !== this.activeMobileOrDesktop) {
          this.changeSvg(this.activeSvgIndex);
        }
      });
    }
  }

  colors: Array<string> = [
    '5c7439', '7ba73b', 'a7873b', 'b4ca96',
    '3af3ff', '2e94bf', '9d5f1c', 'dcda57',
    'e69226', '106726', '8ebb95', 'd12c2c'
  ]

  activeColor: string = '2e94bf';

  activeTool: string = 'brush';

  popup: boolean = false;

  activeSvgIndex: number =  0;

  svgItems: object;

  svgItemsMobile: object;

  activeSvgBlob = '';

  activeMobileOrDesktop = 'desktop';

  ngOnInit(): void {
  }

  setSvgItems = (items) => {
    var result = items.filter(gallery => gallery.nameSet == "gallery_coloring_book" ? gallery : null);
    if(result && result[0]) {
      this.svgItems = result[0].gallery;
      this.changeSvg(this.activeSvgIndex);
    }

    var result2 = items.filter(gallery => gallery.nameSet == "gallery_coloring_book_mobile" ? gallery : null);
    if(result2 && result2[0]) {
      this.svgItemsMobile = result2[0].gallery;
      this.changeSvg(this.activeSvgIndex);
    }
  }

  addEventListerForColoring = () => {
    let getSVG = document.querySelector('.coloring-book');
    let svg = getSVG.querySelector('svg')
    let getFillGroup = svg.getElementById('Fill');
    let getAllRects = getFillGroup.querySelectorAll('rect');
    let getAllCircles = getFillGroup.querySelectorAll('circle');
    let getAllPaths = getFillGroup.querySelectorAll('path');
    getAllRects.forEach(el => {
      el.addEventListener('click', this.colorElement)
    });
    getAllCircles.forEach(el => {
      el.addEventListener('click', this.colorElement)
    });
    getAllPaths.forEach(el => {
      el.addEventListener('click', this.colorElement)
    });
  }

  colorElement = (e) => {
    let el = e.target;
    el.style.cssText = this.activeTool === 'brush' ? 'fill: #' + this.activeColor : 'fill: #ffffff';
  }

  openPopup = () => {
    this.popup = true
  }

  changeSvg = async (number) => {
    this.activeSvgIndex = number;
    this.popup = false;
    var url = this.svgItems[this.activeSvgIndex].src;
    if(window.innerWidth < 768) {
      url = this.svgItemsMobile[this.activeSvgIndex].src;
      this.activeMobileOrDesktop = 'mobile';
    }else{
      this.activeMobileOrDesktop = 'desktop';
    }
    await axios({
      url: url,
      method: 'GET',
      responseType: 'text', // important
    }).then((response) => {
      this.activeSvgBlob = response.data;
      if(document.getElementById('svg-child')) {
        document.getElementById('svg-child').remove();
      }
      var div = document.createElement("div");
      div.innerHTML = response.data;
      div.id = "svg-child";
      document.querySelector('.coloring-book').appendChild(div);
     
    });

    await this.addEventListerForColoring();

  }
  
}
