import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  gallery$:Observable<object>

  gallery = null;

  constructor(private store: Store<{ gallery: object }>) {
    this.gallery$ = store.pipe(select('gallery'));
  }

  ngOnInit(): void {
    this.gallery$.subscribe(gallery => this.gallery = gallery);
  }
}
