<nav>
    <div class="logo" (click)="home()">
        <img src="./assets/img/rdos-logo.svg" alt="logo">
        <img src="./assets/img/q-t-logo.svg" alt="logo">
    </div>
    <div class="hamburger-wrap">
        <div class="label">Menu</div>
        <div class="hambg" [ngClass]="{'open': hamburgerActive }" (click)="hamburgerActive = !hamburgerActive">
            <span></span>
            <span></span>
            <span></span>
        </div>
      </div>
    <ul [ngClass]="{'open': hamburgerActive }">
        <li class="list" [ngClass]="{'opened': openedList }" (click)="openedList = !openedList">
            <a >Rezerwat Informacje</a>
            <ul>
                <ng-container *ngFor="let page of pages">
                    <li *ngIf="page.slug !== 'l-wyczolkowski'">
                        <a routerLink="/{{page.slug}}" (click)="hamburgerActive = !hamburgerActive">{{page.name}}</a>
                    </li>
                </ng-container>
            </ul>
        </li>
        <li>
            <a routerLink="/l-wyczolkowski"  (click)="hamburgerActive = !hamburgerActive">L. Wyczółkowski</a>
        </li>
        <li>
            <a routerLink="/galeria-i-filmy" (click)="hamburgerActive = !hamburgerActive">Galeria i Filmy</a>
        </li>
        <li>
            <a routerLink="/quiz" (click)="hamburgerActive = !hamburgerActive">Quiz</a>
        </li>
        <li>
            <a routerLink="/kolorowanka" (click)="hamburgerActive = !hamburgerActive" >Kolorowanka</a>
        </li>
    </ul>
</nav>
