<loader></loader>
<div class="scene-container">
    <a id="entervr" >
        <img id="myEnterVRButton" src="./assets/img/vr.png" alt="Tryb Vr" crossorigin="anonymous">
    </a>

    <a-scene id="main-scene"  cursor="rayOrigin: mouse" 
            inspector="#"
            vr-mode-ui="enterVRButton: #entervr;" 
            *ngIf="imageBg" 
            loading-screen="enabled: false">
        <a-sky src="#bg" rotation="0 0 0"></a-sky>

        <a-assets >
            <img id="bg" *ngIf="imageBg" [src]="imageBg" (load)="loadImg()" crossorigin="anonymous" />
            <img id="info" src="./assets/img/vr-info.png" />
            <img id="pin" src="./assets/img/vr-teleport.png" />
            <img id="pin-featured" src="./assets/img/vr-teleport-featured.png" />
            <img id="gallery" src="./assets/img/vr-image.png" />
            <img id="audio" src="./assets/img/vr-audio.png" />
            <img id="video" src="./assets/img/vr-video.png" />
        </a-assets>
        <a-entity *ngFor="let el of cameraRotation" camera="active" id="cameraWrapper" [attr.rotation]="el" >
            <a-camera [attr.rotation]="cameraRotation" wasd-controls-enabled="false" >
                <a-cursor material="color: #495535; shader: flat"></a-cursor>
                <a-entity id="clickPosition" position="0 0 -8"></a-entity>
            </a-camera>
        </a-entity>
        <audio id="scene-audio" *ngIf="audio" (ended)="playBgAudio('play')">
            <source [src]="audio.src" type="audio/mp3">
        </audio>
        <audio id="bg-audio" loop>
            <source src="./assets/audio/bg-sound.mp3" type="audio/mp3">
        </audio>

        <a-plane class="vr-el" *ngFor="let el of vrElements" [attr.position]="el.position" [attr.rotation]="el.rotation" [attr.opacity]="1" [attr.data-id]="el.id" [attr.data-type]="el.src" transparent="true" [attr.src]="'#' + el.src + (el.featured == 'true' ? '-featured': '')"  [attr.data-travel]=" el.travel ? el.travel : null" width=".6" height=".6" material="shader: flat" geometry="segmentsHeight: 20; segmentsWidth: 20" (click)="clickVrElement(el)"></a-plane>
    </a-scene >

    <!-- Info Popup -->
    <div class="popup" *ngFor="let el of vrPopups" [attr.data-i]="el.id">
        <div class="popup-container">
            <div class="close-popup" (click)="closePopup(el.id)">
                <img src="./assets/img/close.svg" alt="Zamknij">
            </div>
            <audio-player [src]="el.audio.src" *ngIf="el.audio"></audio-player>
            <div class="container">
                <div class="column">
                    <text-content [innerHTML]="el.text" *ngIf="el.text"></text-content>
                </div>
                <div class="column" *ngIf="el.images">
                    <div class="images" *ngFor="let img of el.images">
                        <div class="img">
                            <img [src]="img.imageSrc">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Gallery Popup -->
    <div class="popup gallery"  *ngFor="let el of vrPopupsGallery" [attr.data-i]="el.id">
        <div class="popup-container">
            <div class="close-popup" (click)="closePopup(el.id)">
                <img src="./assets/img/close.svg" alt="Zamknij">
            </div>
            <div class="arrows">
                <img src="./assets/img/arrow.svg" class="arrow left" alt="poprzednie" (click)="prevGallery( el )">
                <div class="arrow-box arrow-box-left"></div>
                <img src="./assets/img/arrow.svg" class="arrow right" alt="Nastęne" (click)="nextGallery( el )">
                <div class="arrow-box arrow-box-right"></div>
            </div>
            <div class="image">
                <img [src]="el.images[activeIndex].imageSrc" >
            </div>
        </div>
    </div>

    <!-- Audio Popup -->
    <div class="audio-popup" *ngFor="let el of vrPopupsAudio" [attr.data-i]="el.id">
        <audio id="audio-popup-audio" (ended)="resetAudio(el.id)">
            <source [src]="el.src" type="audio/mp3">
        </audio>
    </div>

    <!-- Video Popup -->
    <div class="popup video-popup" *ngFor="let el of vrPopupsVideo" [attr.data-i]="el.id">
        <div class="popup-container">
            <div class="close-popup" (click)="closePopup(el.id)">
                <img src="./assets/img/close.svg" alt="Zamknij">
            </div>
            <div class="video" [attr.data-yt-id]=" el.ytID " *ngIf="el.ytID">
                <iframe width="1280" height="800" [src]="('https://www.youtube.com/embed/' + el.ytID) | youtube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>