<close-page></close-page>
<div class="choose-option">
    <div class="option" [ngClass]="{'active': mapOption === 'google'}" (click)="changeMap('google')">Google Maps</div>
    <div class="option" [ngClass]="{'active': mapOption === 'route'}" (click)="changeMap('route')">Wyczólkowski</div>
</div>

<div class="google-maps" *ngIf="(apiLoaded | async) && (mapOption === 'google')">
    <google-map [options]="options" 
                height="550px"
                width="100%"></google-map>
</div>

<div class="drawed-map" *ngIf="mapOption !== 'google'">
    <div class="drawed-scroll">
        <div class="map">
            <img src="./assets/img/map-drawed.png" alt="Mapa">
        </div>
        <div class="pins">
            <ng-container *ngFor="let item of mapDefault">
                <div class="pin" [ngStyle]="setMyStyles(item)" *ngIf="mapOption !== 'route'">
                    <map-pin [color]="'#930505'" [data]="item"></map-pin>
                </div>
            </ng-container>
            <ng-container *ngFor="let item of mapWyczolkowski">
                <div class="pin" [ngStyle]="setMyStyles(item)" *ngIf="mapOption == 'route'">
                    <map-pin [color]="'#930505'" [data]="item"></map-pin>
                </div>
            </ng-container>
        </div>
    </div>
</div>