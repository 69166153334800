<div class="audio-player-wrap">
    <div class="audio-player">
        <div class="play-icon">
            <img src="./assets/img/play-icon.png" 
                alt="Odtwórz" 
                (click)="playAudio()"
                *ngIf="!play">
            
            <img src="./assets/img/pause-icon.png" 
                alt="zatrzymaj" 
                (click)="pauseAudio()"
                *ngIf="play">
        </div>
        <div class="duration">
            {{ convertTime(currenDuration) }}/{{ convertTime(duration) }}
        </div>
        <div class="progress-bar">
            <div class="empty">
                <div class="full" [ngStyle]="{'transform': 'translateX('+ getBarPosition() +'px)'}"></div>
            </div>
        </div>
        <div class="volume">
            <div class="icon">
                <img src="./assets/img/audio-icon.png" alt="Audio">
            </div>
        </div>
        <audio id="audio-wrap" (ended)="endAudio()">
            <source [src]="src" type="audio/mp3">
        </audio>
    </div>
</div>