import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'navigation-top',
  templateUrl: './navigation-top.component.html',
  styleUrls: ['./navigation-top.component.scss']
})
export class NavigationTopComponent implements OnInit {
  pages$:Observable<object>

  hamburgerActive: boolean = false;
  openedList: boolean = false;
  pages = null;
  
  constructor(private router: Router, private store: Store<{ pages: object }>) {
    this.pages$ = store.pipe(select('pages'));
  }

  ngOnInit(): void {
    this.pages$.subscribe(pages => this.pages = pages);
  }

  home = () => {
    this.router.navigate(['/'], {});
  }
}
