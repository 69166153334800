import { Component, OnInit } from '@angular/core';
import ApiUrl from './../../services/ApiUrl';
import axios from 'axios';
import { Router } from '@angular/router';

@Component({
  selector: 'quiz-wynik',
  templateUrl: './quiz-wynik.component.html',
  styleUrls: ['./quiz-wynik.component.scss']
})
export class QuizWynikComponent implements OnInit {

  constructor(private router: Router) { }

  name: string = "";
  email: string = "";
  btnText: string = "Wyślij dyplom";
  blockedBtn = false;

  ngOnInit(): void {
    var quiz = sessionStorage.getItem('quiz');
    if(!quiz) {
      this.router.navigate(['/quiz'], {});
    }
  }

  changeInput = (e, type) => {
    this[type] = e.target.value
  }

  generatePDF = async(typeClick) => {
    if(!this.blockedBtn) {
      this.blockedBtn = true;
      var errors = false;

      if(typeClick == "email") {
        if(this.email == "") {
          errors = true;
          document.getElementById('inputEmail').classList.add('error');
          this.blockedBtn = false;
        }else{
          var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
          if(regexEmail.test(this.email)) {
            document.getElementById('inputEmail').classList.remove('error');  
          }else{
            errors = true;
            document.getElementById('inputEmail').classList.add('error');   
            this.blockedBtn = false;
          }
        }
      }

      if(this.name == "") {
        errors = true;
        document.getElementById('inputName').classList.add('error');
        this.blockedBtn = false;
      }else{
        document.getElementById('inputName').classList.remove('error');
      }
      if(!errors) {
        await axios({
          method: 'post',
          url: `${ApiUrl()}/generatePDF`,
          data: {
            email: this.email,
            name: this.name,
            type: typeClick,
          }
        }).then(async response => {
          this.email = "";
          this.name = "";
          this.blockedBtn = false;
          document.getElementById('inputEmail').classList.remove('error');  
          document.getElementById('inputName').classList.remove('error');  
          if(typeClick == "email") {
            this.btnText = "Wysłano";
          }else{
            window.open(response.data, '_blank');
          }
        })
      }
    }
  }

  // downloadURL = (url) => {
  //   var link = document.createElement('a');
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'filename');
  //   link.click();
  // }

}
