import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import getDataFromUrl from './../../services/getDataFromUrl.js';
import ApiUrl from './../../services/ApiUrl.js';
import axios from 'axios';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-galleryvr',
  templateUrl: './galleryvr.component.html',
  styleUrls: ['./galleryvr.component.scss']
})
export class GalleryvrComponent implements OnInit {

  constructor(public sanitizer: DomSanitizer, private router: Router,  private location: Location){}

  imageBg: string;
  audio: object = null;
  vrElements: Array<object>;
  vrPopups: Array<object>;
  vrPopupsGallery: any;
  vrPopupsAudio: Array<object>;
  vrPopupsVideo: Array<object>;
  cameraRotation: Array<string> = null;
  vrMode: boolean = false;
  sceneLoaded: boolean = false;
  activeIndex: number = 0;
  audioEl:any = null ;
  CzubownaVolume: number =  .8;

  ngOnInit(): void {
    this.getMainImageVr();
    this.changeTabDetect();

  }

  goBack() {
    this.location.back();
  }

  changeTabDetect = () => {
    window.addEventListener('blur', (e) => {
      // your custom code here
      let audios = document.querySelectorAll('audio');
      if ( audios.length > 0 ) {
        audios.forEach( el => {
          el.pause();
        });
      }
    });
  }

  playBgAudio(method) {
    let el = <HTMLAudioElement> document.getElementById('bg-audio');

    if ( method === 'play') {
      el.play();
    }
    if ( method === 'pause') {
      el.pause();
    }
    if ( method === 'silent') {
      el.volume = 0.5;
    }
  }
  resetAudio(id) {
    this.playBgAudio('play');
    let audioContainer = document.querySelector('[data-i="'+ id +'"]');
    audioContainer.classList.remove('played')
    let audioEl = <HTMLAudioElement> audioContainer.querySelector('#audio-popup-audio');
    audioEl.pause();
    audioEl.currentTime = 0;
  }

  loadImg() {
    setTimeout(() => {
      document.getElementById('custom-loader').classList.remove("block");
    }, 1000);
    this.sceneLoaded = true;
    this.playBgAudio('play')  

    if (  this.audio && this.sceneLoaded ) {
      this.playSceneSound();  
      this.playBgAudio('silent')  
    }
  }
  clickVrElement = async(el) => {
    let getType = el.src;

    if ( getType === 'info' || getType === 'gallery' || getType === 'video') {
      let getId = el.id;
      document.querySelector(`[data-i*="${ getId }"]`).classList.add('active');
    }
    if ( getType === 'audio') {
      let elScene = <HTMLAudioElement> document.getElementById('scene-audio');
      if ( elScene ) {
        elScene.pause();
        elScene.currentTime = 0;
      }
      
      let getId = el.id;
      let audioContainer = document.querySelector('[data-i="'+ getId +'"]');
      let audioEl = <HTMLAudioElement> audioContainer.querySelector('#audio-popup-audio');
      audioEl.volume = this.CzubownaVolume;

      if ( !audioContainer.classList.contains('played') ) {
        audioContainer.classList.add('played')
        audioEl.play();
        this.playBgAudio('silent'); 

      } else {
        audioContainer.classList.remove('played')
        audioEl.pause();
        this.playBgAudio('play'); 
      }
     
    }
    if ( getType === 'pin' ) {
      if ( this.audioEl ) {
        this.audioEl = null;
        this.audio = null;
      }
      await this.router.navigate([], { 
        queryParams: {
          i: el.travel,
        },
      });
      document.getElementById('custom-loader').classList.add("block");
      await this.getMainImageVr(true);
      
    }
  }
 
  closePopup(id) {
    document.querySelector(`[data-i*="${ id }"]`).classList.remove('active');
  }

  prevGallery = (el) => {
    let maxItems = el.images.length - 1;
    let tempIndex = this.activeIndex - 1;
    if ( tempIndex < 0) {
      tempIndex = maxItems;
    }
    this.activeIndex = tempIndex ;
  }
  
  nextGallery = (el) => {
    let maxItems = el.images.length - 1;
    let tempIndex = this.activeIndex + 1;
    if ( tempIndex > maxItems) {
      tempIndex = 0;
    }
    this.activeIndex = tempIndex ;
  }

  playSceneSound() {
    let el = <HTMLAudioElement> document.getElementById('scene-audio');
    this.audioEl = el;
    el.volume = this.CzubownaVolume;
    el.play();
  }

  getMainImageVr = async (reload?) => {
    let srcImg = getDataFromUrl('i');
    if(srcImg !== "0")  {
      await axios({
        method: 'post',
        url: `${ApiUrl()}/getMainImageVr`,
        data: {
          imageID: srcImg,
        }
      }).then(async response => {
        this.imageBg = response.data.main_img_url;
          if(reload) {
            this.vrElements = null;
            this.vrPopups = null;
            this.cameraRotation = null;
            this.vrElements = null;
            this.vrPopups = null;
            this.cameraRotation = null;
            this.audio = null;
            this.vrPopupsGallery = null;
            this.vrPopupsAudio = null;
            this.vrPopupsVideo = null;
            this.activeIndex = 0;
            this.audioEl = null ;
            document.querySelector('a-sky').setAttribute('src', response.data.main_img_url);
          }
          if ( response.data.content ) {
            let data = JSON.parse( response.data.content )
            if ( data.pins )
              this.vrElements = data.pins;

            if ( data.popupsInfo )
              this.vrPopups = data.popupsInfo;
            
            if ( data.popupsGallery )
              this.vrPopupsGallery = data.popupsGallery

            if ( data.popupsAudio )
              this.vrPopupsAudio = data.popupsAudio

            if ( data.popupsVideo )
              this.vrPopupsVideo = data.popupsVideo
            

            if ( data.cameraRotation ) {
              if(typeof window !== "undefined") {
                this.changeRotation(data.cameraRotation);
              }
            }else{
              if(typeof window !== "undefined") {
                this.changeRotation("0 0 0");
              }
            }

          }else{
            this.vrElements = null;
            this.vrPopups = null;
            this.cameraRotation = null;
            this.audio = null;
            this.vrPopupsGallery = null;
            this.vrPopupsAudio = null;
            this.vrPopupsVideo = null;
            this.activeIndex = 0;
            this.audioEl = null ;
          }

          if ( response.data.audio ) {
            let data = JSON.parse( response.data.audio )
            if ( data ) {
              this.audio = data.audio;
            }
          }
              
          
          
      })
    }
  }

  changeRotation(value) {
      this.cameraRotation = [value];
  }
}
