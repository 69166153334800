import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'brush',
  templateUrl: './brush.component.html',
  styleUrls: ['./brush.component.scss']
})
export class BrushComponent implements OnInit {

  @Input() color: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
