import { Component, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import { getTranslate } from './../../services/getTranslate';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  @ViewChild('slickModal')
  public slickModal: any;

  constructor(private store: Store<{ }>, private router: Router) { 
    this.router.navigate(['/'], { 
      queryParams: {}
    });
  }

  ngOnInit(): void {
    this.removeVrClass();
  }

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

}
