import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ColoringBookComponent } from './pages/coloring-book/coloring-book.component';
import { DynamicPageComponent } from './pages/dynamic-page/dynamic-page.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
// import { AttractionComponent } from './pages/attraction/attraction.component';
// import { Gallery2dComponent } from './pages/gallery2d/gallery2d.component';
import { HomeComponent } from './pages/home/home.component';
import { MapComponent } from './pages/map/map.component';
import { QuizWynikComponent } from './pages/quiz-wynik/quiz-wynik.component';
import { QuizComponent } from './pages/quiz/quiz.component';
// import { LocalitiesComponent } from './pages/localities/localities.component';
// import { LocalityComponent } from './pages/locality/locality.component';
// import { MapComponent } from './pages/map/map.component';
import { GalleryvrComponent } from './pages/galleryvr/galleryvr.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'galeria-i-filmy', component: GalleryComponent },
  { path: 'quiz', component: QuizComponent },
  { path: 'quiz/wynik', component: QuizWynikComponent },
  { path: 'mapa', component: MapComponent },
  { path: 'kolorowanka', component: ColoringBookComponent },
  { path: 'wirtualny-spacer', component: GalleryvrComponent },
  { path: ':dynamic', component: DynamicPageComponent },
  // { path: 'miejscowosci', component: LocalitiesComponent },
  // { path: 'miejscowosci/:locality', component: LocalityComponent },
  // { path: 'miejscowosci/:locality/galeria-2d', component: Gallery2dComponent },
  // { path: 'miejscowosci/:locality/galeria-vr', component: GalleryvrComponent },
  // { path: 'miejscowosci/:locality/:attraction', component: AttractionComponent },
  // { path: 'miejscowosci/:locality/:attraction/galeria-2d', component: Gallery2dComponent },
  // { path: 'miejscowosci/:locality/:attraction/galeria-vr', component: GalleryvrComponent },
  // { path: 'map', component: MapComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  
 }
