import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  constructor() { }

  @Input() src: string;

  play: boolean = false;
  duration:number = 0;
  currenDuration:number = 0;
  audioElement: HTMLAudioElement;
  interval: any;

  ngOnInit(): void {
    this.initAudio();
  }

  async initAudio() {
    if ( document.getElementById('audio-wrap') ) {
      try {
        this.audioElement = <HTMLAudioElement> document.getElementById('audio-wrap');
        await this.audioElement.play();
        await this.audioElement.pause();
        await this.getDuration();
      } catch (e) {
        setTimeout(() => {
          this.initAudio()
        }, 200);
      }
    } else {
      await this.initAudio();
    }
  }

  getDuration() {
    this.duration = this.audioElement.duration;
  }

  playAudio() {
    this.audioElement.play();
    this.play = true;
    this.interval = setInterval( () => this.currenDuration  = this.audioElement.currentTime, 100);
    
  }

  pauseAudio() {
    this.audioElement.pause();
    this.play = false;
    clearInterval(this.interval)
  }

  endAudio() {
    this.audioElement.pause();
    this.audioElement.currentTime = 0;
    this.play = false;
    this.currenDuration = 0;
  }

  getBarPosition() {
    let prct = (this.currenDuration * 100) / this.duration;
    let px = (prct * 66) / 100;
    return px; 
  }

  convertTime( time ) {
    let getMinutes = Math.floor(time / 60);
    let getSeconds =  Math.floor(time - getMinutes * 60);
    let minutesToString = getMinutes.toString();
    let secondsToString = getSeconds.toString();
    if (getSeconds < 10) {
      secondsToString = '0' + getSeconds.toString();
    }
    let generateString = minutesToString + ':' + secondsToString;

    return generateString;
  }
}
