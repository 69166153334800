import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dynamic-page',
  templateUrl: './dynamic-page.component.html',
  styleUrls: ['./dynamic-page.component.scss']
})
export class DynamicPageComponent implements OnInit {
  pages$:Observable<object>

  page: object;
  pages = null;
  slug: string;

  constructor(private router: Router, private store: Store<{ pages: object }>) {
    this.pages$ = store.pipe(select('pages'));
    
    if(!this.slug) {
      store.select('pages').subscribe((pages) => this.getPage(pages))
    }
  
    if(this.slug) {
      router.events.subscribe((event) => {
        if(this.router.url !== `/${this.slug}`) {
          this.getPage(this.pages);
        }
      });
    }

  }

  ngOnInit(): void {
    this.removeVrClass();
  }

  getPage = (pages) => {
    this.slug = (this.router.url).replace("/", "");
    this.pages = pages;
    let thisPage = pages.filter(page => page.slug == this.slug);
    this.page = thisPage[0];
  }

  log = (val) => console.log(val);

  removeVrClass = () => {
    if(typeof window !== "undefined") {
      var item = document.querySelector(".a-fullscreen");
      if(item && item.classList) {
        item.classList.remove("a-fullscreen")
      }
    }
  }

}
