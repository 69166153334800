import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { StoreModule } from "@ngrx/store";

import { PagesReducer } from './reducers/pages.reducer';
import { QuizReducer } from './reducers/quiz.reducer';
import { MapPathReducer } from './reducers/map-path.reducer';
import { GallerySetsReducer } from './reducers/gallery-sets.reducer';
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavigationComponent } from './components/navigation-bottom/navigation.component';
import { NavigationTopComponent } from './components/navigation-top/navigation-top.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { ClosePageComponent } from './components/close-page/close-page.component';
import { MediaListComponent } from './components/media-list/media-list.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { QuizWynikComponent } from './pages/quiz-wynik/quiz-wynik.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MapComponent } from './pages/map/map.component';
import { GalleryvrComponent } from './pages/galleryvr/galleryvr.component';
import { MapPinComponent } from './components/map-pin/map-pin.component';
import { ColoringBookComponent } from './pages/coloring-book/coloring-book.component';
import { DynamicPageComponent } from './pages/dynamic-page/dynamic-page.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { TextContentComponent } from './components/text-content/text-content.component';
import { BrushComponent } from './svg/brush/brush.component';
import { EraserComponent } from './svg/eraser/eraser.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { YoutubePipe } from './pipes/youtube.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    NavigationTopComponent,
    GalleryComponent,
    PageTitleComponent,
    ClosePageComponent,
    MediaListComponent,
    QuizComponent,
    QuizWynikComponent,
    MapComponent,
    MapPinComponent,
    ColoringBookComponent,
    DynamicPageComponent,
    AudioPlayerComponent,
    TextContentComponent,
    BrushComponent,
    EraserComponent,
    GalleryvrComponent,
    LoaderComponent,
    YoutubePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    AppRoutingModule,
    SlickCarouselModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    StoreModule.forRoot({ pages: PagesReducer, quiz: QuizReducer, gallery: GallerySetsReducer, map: MapPathReducer })
  ],
  providers: [
    { provide: 'googleTagManagerId', useValue: 'GTM-K62Q8HX' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {

}

