import { createReducer, on } from '@ngrx/store';
import { setGallerySets } from './../actions/gallery-sets.actions';

export const initialState = [];
 
const _GallerySetsReducer = createReducer(initialState,
  on(setGallerySets, (state, action) => action.gallery) ,
);

export function GallerySetsReducer(state, action) {
    return _GallerySetsReducer(state, action);
}