<loader *ngIf="loader && routeUrl$ && routeUrl$.indexOf('wirtualny-spacer') === -1"></loader>
<div class="ghost"></div>
<navigation-top></navigation-top>
<div class="bg" *ngIf="routeUrl$ && routeUrl$.indexOf('wirtualny-spacer') === -1">
    <ngx-slick-carousel class="carousel" 
                        [config]="slideConfig"
                        *ngIf="sliderImages && sliderImages.length > 0">
        <div ngxSlickItem *ngFor="let slide of sliderImages" class="slide">
            <img [src]="slide.sizes.big" [srcset]="slide.sizes.small + ' 640w, ' + slide.sizes.medium + ' 1280w, ' + slide.sizes.big + ' 1281w'" [alt]="slide.alt" class="main-bg">
        </div>
    </ngx-slick-carousel>
</div>
<div class="container main-title" *ngIf="routeUrl$ && routeUrl$.indexOf('wirtualny-spacer') === -1">
    <h2>Rezerwat przyrody</h2>
    <h1>Cisy Staropolskie</h1>
    <h2>im. Leona Wyczółkowskiego</h2>
</div>

<div [class]="{
                'page': routeUrl$ && routeUrl$ !== '/' && routeUrl$.indexOf('wirtualny-spacer') === -1,
                'vr': routeUrl$ && routeUrl$.indexOf('wirtualny-spacer') > -1
            }"> 
    <router-outlet></router-outlet>
</div>

<navigation></navigation>
<!-- <loader *ngIf="loader"></loader> -->