import { createReducer, on } from '@ngrx/store';
import { setPages } from './../actions/pages.actions';

export const initialState = [];
 
const _PagesReducer = createReducer(initialState,
  on(setPages, (state, action) => action.pages) ,
);

export function PagesReducer(state, action) {
    return _PagesReducer(state, action);
}