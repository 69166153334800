import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'map-pin',
  templateUrl: './map-pin.component.html',
  styleUrls: ['./map-pin.component.scss']
})
export class MapPinComponent implements OnInit {

  activeInfo: boolean = false;
  izbaText = `<p>Izba ekspozycyjno-edukacyjna i parking przy rezerwacie Cisy Staropolskie im. Leona Wyczółkowskiego w Wierzchlesie.</p>
  <p>Budynek dawnej piwnicy łowieckiej izby został zmodernizowany w 2013 r. i zaadaptowany na potrzeby izby edukacyjno-ekspozycyjnej.</p>
  <p>Wyposażenie izby umożliwia prowadzenie zajęć edukacyjnych dla około 30-osobowych grup.</p>
  <p>W izbie wyeksponowano też ok. 25 reprodukcji obrazów malarza Leona Wyczółkowskiego, tematycznie związanych z rezerwatem.</p>
  <p>Niedaleko budynku izby - na parkingu przy dróżce do rezerwatu znajdują się wiaty turystyczne i zadaszenie z miejscem na ognisko.</p>`
  izbaPopup = false;
  @Input() color: string = '#000000';
  @Input() data;

  constructor() { }

  ngOnInit(): void {
  }

  togglePopup = () => {
    this.izbaPopup = !this.izbaPopup;
  }

}
