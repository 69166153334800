<close-page></close-page>
<page-title [title]="'Quiz - Wynik'"></page-title>

<div class="title">
    <h5>Gratulacje! Quiz został poprawnie rozwiązany</h5>
    <p>Podaj swoje imię i nazwisko dla pamiątkowego dyplomu</p>
    <input type="text" id="inputName" placeholder="Imię i Nazwisko" [value]="name" (change)="changeInput($event, 'name')">
    <p>Wyślij pamiątkowy dyplom na swojego emaila</p>
    <input type="text" id="inputEmail" placeholder="Adres e-mail" [value]="email" (change)="changeInput($event, 'email')">
</div>

<div class="btn-wrap">
    <div class="btn" (click)="generatePDF('download')">Wydrukuj dyplom</div>
    <div class="btn" (click)="generatePDF('email')">{{btnText}}</div>
</div>
