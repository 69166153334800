import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import ApiUrl from './../../services/ApiUrl';
import axios from 'axios';
import { throws } from 'assert';

@Component({
  selector: 'quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  quiz$:Observable<object>

  chooseDiff: number = 0;
  quiz: object;
  questionsAnswers = [];
  load = false;
  blockedBtn = false;
  good: number = 0;
  bad: number = 0;
  popup: boolean = false;

  constructor(private router: Router, private store: Store<{ quiz: object }>) {
    this.quiz$ = store.pipe(select('quiz'));

    store.select('quiz').subscribe((quiz) => {
      if(quiz[0]) {
        this.setDiff(0, quiz);
      }
    })
  }

  async ngOnInit() {
    await this.quiz$.subscribe(quiz => this.quiz = quiz);
  }

  setDiff = (value, quiz) => {
    this.chooseDiff = value
    var newQuestionsAnswers = [];
    quiz[value].questions.map((question => {
      newQuestionsAnswers = [...newQuestionsAnswers, {
        id: question.id,
        answer: '',
      }]
    }))
    this.questionsAnswers = newQuestionsAnswers;
    this.load = true;
  };

  setAnswer = (letter, index) => {
    var newQuestionsAnswers = this.questionsAnswers.filter((item, i) => i == index ? item.answer = letter : item);
    this.questionsAnswers = newQuestionsAnswers;
    let parent = document.querySelector('[data-lp="' + index +'"]')
    
    if ( parent.classList.contains('error') ) {
      parent.classList.remove('error');
    }
  }

  checkAnswers = async(el) => {
    this.bad = 0;
    this.good = 0;
    if(!this.blockedBtn) {
      this.blockedBtn = true;
      let errors;
      for(var i = 0; i<this.questionsAnswers.length; i++) {
        if(this.questionsAnswers[i].answer == "") {
          document.querySelectorAll('.question')[i].classList.add('error');
        }else{
          document.querySelectorAll('.question')[i].classList.remove('error');
        }
      }
      let elements = document.querySelectorAll('.questions .error');
      if( elements.length > 0 ) {
        errors = true;
      } else {
        errors = false;
      }

      if(!errors) {
        await axios({
          method: 'post',
          url: `${ApiUrl()}/checkQuizAnswers`,
          data: {
            answers: this.questionsAnswers,
          }
        }).then(async response => {
          let data = response.data;
          for(let x = 0; x<data.length; x++) {
            if(data[x]['status'] == "BAD") {
              document.querySelectorAll('.question')[x].classList.add('error');
              this.bad++;
            }else{
              document.querySelectorAll('.question')[x].classList.remove('error');
              this.good++;
            }
          }
          let elements = document.querySelectorAll('.questions .error');
          if( elements.length > 0 ) {
            errors = true;
          } else {
            errors = false;
          }
          if(errors) {
            this.scrollToTop(el);
            this.popup = true;
          }else{
            sessionStorage.setItem('quiz', 'true');
            this.router.navigate(['/quiz/wynik'], {});
          }
        })
      }else{  
        this.scrollToTop(el);
      }
    }
  }

  scrollToTop(el) {
    this.blockedBtn = false;
    var offset = 112;
    window.scroll({ top: (el.offsetTop - offset), left: 0, behavior: 'smooth' });
  }

  togglePopup = () => {
    this.popup = false;
  }

}
