import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { getTranslate } from './../../services/getTranslate';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  routeUrl$:string;

  constructor(private router: Router, private store: Store<{ }>, private location: Location) {
    router.events.subscribe(() => 
      this.routeUrl$ = router.url
    );
   }

  ngOnInit(): void {

  }
  backPage = () => {
    this.location.back();
  }

  redirectTo(uri: string, param: number){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri],  { queryParams: { i: param }} ));
 }

}
