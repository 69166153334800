import { createReducer, on } from '@ngrx/store';
import { setQuiz } from './../actions/quiz.actions';

export const initialState = [];
 
const _QuizReducer = createReducer(initialState,
  on(setQuiz, (state, action) => action.quiz) ,
);

export function QuizReducer(state, action) {
    return _QuizReducer(state, action);
}