import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent implements OnInit {

  constructor() { }

  activeIndex: number =  -1;
  activeModal: boolean = false;

  @Input() title: string;
  @Input() type: string;
  @Input() data: Array<object>;

  ngOnInit(): void {
  }

  ytEmbed = (url) => {
    if (url != undefined || url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return 'https://www.youtube.com/embed/' + match[2];
        }
    }
  }

  open(index: number): void {
    this.activeIndex = index;
    this.activeModal = true;
  }
 
  close(): void {
    this.activeIndex = -1;
    this.activeModal = false;
  }

  prevGallery = () => {
    let maxItems = this.data.length - 1;
    let tempIndex = this.activeIndex - 1;
    if ( tempIndex < 0) {
      tempIndex = maxItems;
    }
    this.activeIndex = tempIndex ;
  }
  
  nextGallery = () => {
    let maxItems = this.data.length - 1;
    let tempIndex = this.activeIndex + 1;
    if ( tempIndex > maxItems) {
      tempIndex = 0;
    }
    this.activeIndex = tempIndex ;
  }

}
