<ng-container *ngIf="load">
    <close-page></close-page>
    <page-title [title]="'Quiz'"></page-title>

    <div [className]="popup ? 'popup-quiz popup active' : 'popup-quiz popup'" *ngIf="popup">
        <div class="popup-container">
            <div class="close-popup" (click)="togglePopup()">
                <img src="./assets/img/close.svg" alt="Zamknij">
            </div>
            <div class="container">
                <p>Udzieliłeś poprawnej odpowiedzi dla <span class="good">{{good}}</span>/<span class="limit">{{quiz[chooseDiff].questions.length}}</span> pytań.<br>Aby przejść dalej, popraw błędne odpowiedzi.</p>
            </div>
        </div>
    </div>
    
    <div class="title">
        <h5>Sprawdź się! Odpowiedz na 15 pytań w Quizie i wydrukuj pamiątkowy dyplom z Twoim wynikiem. </h5>
        <p>Wybierz poziom trudności</p>
    </div>
    
    <div class="choose-difficulty">
        <ng-container *ngFor="let item of quiz; let i = index">
            <div [className]="chooseDiff == i ? 'diff active' : 'diff'" (click)="setDiff(i, quiz)">{{item.text}}</div>
        </ng-container>
    </div>

    <div class="questions" #questions>
        <ng-container *ngFor="let quest of quiz[chooseDiff].questions; let lp = index">
            <div class="question" [attr.data-lp]="lp">
                <p><b>{{lp + 1}}.</b> {{quest.question}}</p>
                <div [className]="quest.display == 'linia' ? 'answears line' : 'answears' ">
                    <div [className]="questionsAnswers[lp].answer == 'a' ? 'answear active' : 'answear'" (click)="setAnswer('a', lp)">
                        <span>a</span>
                        {{quest.a}}
                    </div>
                    <div [className]="questionsAnswers[lp].answer == 'b' ? 'answear active' : 'answear'" (click)="setAnswer('b', lp)">
                        <span>b</span>
                        {{quest.b}}
                    </div>
                    <div [className]="questionsAnswers[lp].answer == 'c' ? 'answear active' : 'answear'" (click)="setAnswer('c', lp)">
                        <span>c</span>
                        {{quest.c}}
                    </div>
                    <div [className]="questionsAnswers[lp].answer == 'd' ? 'answear active' : 'answear'" (click)="setAnswer('d', lp)" *ngIf="quest.d">
                        <span>d</span>
                        {{quest.d}}
                    </div>
                    <ng-container *ngIf="quest.question_media">
                        <div class="help-img" *ngFor="let media of quest.question_media">
                            <img [src]="media.sizes.big" [srcset]="media.sizes.small + ' 640w, ' + media.sizes.medium + ' 1280w, ' + media.sizes.big + ' 1281w'" [alt]="media.alt">
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    
    <div class="btn-wrap" (click)="checkAnswers(questions)">
        <div class="btn">{{blockedBtn ? 'Sprawdzanie...' : 'Sprawdź odpowiedzi'}}</div>
    </div>
</ng-container>