import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import {map} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setPages } from './actions/pages.actions';
import { setGallerySets } from './actions/gallery-sets.actions';
import { setMapPath } from './actions/map-path.actions';
import { setQuiz } from './actions/quiz.actions';
import axios from 'axios';
import ApiUrl from './services/ApiUrl';
import IndexOf from './services/indexOf';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

interface AppState {
  message: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Pietrowice Wielkie';
  loader: boolean = true;
  sliderImages = [];

  routeUrl$:string;
  message$: Observable<string>
  slideConfig = { 
                  "slidesToShow": 1, 
                  "slidesToScroll": 1,
                  "autoplay": true,
                  "speed": 2500,
                  "autoplaySpeed": 4000,
                  "fade": true,
                  "infinite": true,
                  "arrows": false,
                  "dots": false,
                  "pauseOnHover": false
                };

  constructor( route: Router, gtmService: GoogleTagManagerService, private store: Store<{ }> ) {
    route.events.subscribe(() => {
      this.routeUrl$ = route.url
      if(   route.url.indexOf('wirtualny-spacer') === -1 ) {
        document.querySelector('html').classList.remove('a-fullscreen')
      }
    })

    route.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        gtmService.pushTag(gtmTag);
      }
    });
  }

  log = (val) => console.log(val);

  async ngOnInit() {
    await this.getGallerySets();
    await this.getPages();
    await this.getMapPath();
    await this.getQuiz();
    this.loader = false;
  }


  checkVr() {
    if ( this.routeUrl$ && this.routeUrl$.indexOf('galeria-vr') > -1) {
      return true;
    } else {
      return false;
    }
  }

  

  getPages = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/pages`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        await this.store.dispatch(setPages({ pages: res.data }));
    })
  }

  getGallerySets = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/gallerySets`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        await this.store.dispatch(setGallerySets({ gallery: res.data }));
        let tempGallery = [];

        res.data.forEach( el => {
          if ( el.nameSet === 'gallery_slider_home' ) {
             tempGallery = el.gallery
          }
        });

        this.sliderImages = tempGallery;
      })
  }


  getQuiz = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/quiz`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        await this.store.dispatch(setQuiz({ quiz: res }));
    })
  }


  getMapPath = async() => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/mapPath`,
    }).then(async response => {
        let res = JSON.parse(response.request.response);
        await this.store.dispatch(setMapPath({ map: res }));
    })
  }
  
}