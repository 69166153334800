<nav id="nav" [class]="{ 'small': routeUrl$ !== '/' }">
    <ul [class]="routeUrl$ == '/' ? 'active' : ''">
        <li>
            <div class="icon">
                <a (click)="redirectTo('/wirtualny-spacer', 6)">
                    <img src="./assets/img/vr-icon.svg" alt="Wirtualny spacer">
                </a>
            </div>
            <span>Wirtualny spacer</span>
            <span class="mobile">Spacer</span>
        </li>
        <li>
            <div class="icon">
                <a [routerLink]="['/mapa']">
                    <img src="./assets/img/map-icon.svg" alt="Mapa">
                </a>
            </div>
            <span>Mapa ze ścieżką</span>
            <span class="mobile">Mapa</span>
        </li>
        <li>
            <div class="icon">
                <a  (click)="redirectTo('/wirtualny-spacer', 57)">
                    <img src="./assets/img/trees-icon.svg" alt="Rezerwat z lotu ptaka">
                </a>
            </div>
            <span>Rezerwat z lotu ptaka</span>
            <span class="mobile">Z góry</span>
        </li>
    </ul>
</nav>