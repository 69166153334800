<ng-container *ngIf="page">
    <close-page></close-page>
    <page-title [title]="page.name"></page-title>
    <ng-container *ngFor="let mediaTop of page.media_top">
        <audio-player *ngIf="mediaTop.type == 'mp3'" [src]="mediaTop.src"></audio-player>
    </ng-container>
    <div class="container">
        <div class="column">
            <text-content [content]="page.text"></text-content>
        </div>
        <div class="column" *ngIf="page.media_right && (page.media_right).length > 0">
            <div class="images" *ngFor="let imgRight of page.media_right">
                <div class="img">
                    <img [src]="imgRight.sizes.small" [alt]="imgRight.alt">
                    <p *ngIf="imgRight.description">{{imgRight.description}}</p>
                </div>
            </div>
            <div class="links" *ngIf="page.name == 'Wstęp'">
                <div class="img">
                    <a href="https://play.google.com/store/apps/details?id=pl.cisystaropolskie&hl=en_US&gl=US" target="__blank">
                        <img src="https://api.cisystaropolskie.pl/images/small/2020-12-01-MsClHx9srPKihACvO1NL2zG1Bymlrh.png" alt="google play">
                    </a>
                </div>
                <div class="img">
                    <a href="https://apps.apple.com/pl/app/cisy-staropolskie-vr-tour/id1541649147?l=pl" target="__blank">
                        <img src="https://api.cisystaropolskie.pl/images/small/2020-12-01-uaM9SiRZk9GCyWixvcfy3LzePxY0nS.png" alt="app store">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <media-list *ngIf="page.media_bottom && (page.media_bottom).length > 0" [title]="'Galeria'" [type]="'image'" [data]="page.media_bottom"></media-list>
</ng-container>
