import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextContentComponent implements OnInit {

  @Input() content: string;

  constructor() { }

  ngOnInit(): void {
  }

}
