<svg (click)="activeInfo = !activeInfo" id="Warstwa_1" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 377.79 556.01"><defs><style>.cls-1{fill:none;}.cls-2{clip-path:url(#clip-path);}</style><clipPath id="clip-path"><rect class="cls-1" width="377.79" height="556.01"/></clipPath></defs><title>Obszar roboczy 1</title><g class="cls-2"><path class="cls-3" [ngStyle]="{'fill': color}"  d="M188.9,0C84.57,0,0,84.57,0,188.9A188.46,188.46,0,0,0,14.67,262C79.75,427,80,427.6,109.76,503h0c25.32,70.64,133,70.62,158.27,0h0l95.08-241a188.28,188.28,0,0,0,14.67-73.11C377.79,84.57,293.22,0,188.9,0m0,314.35C24,311.53,24,66.24,188.9,63.45c164.9,2.81,164.87,248.1,0,250.9"/><circle class="cls-3"  [ngStyle]="{'fill': color}" cx="188.9" cy="194.51" r="56.51"/></g></svg>
<div class="info-modal" [ngClass]="{'active': activeInfo }">
    <div class="close" (click)="activeInfo = false">
        <img src="./assets/img/close.png" alt="Zamknij">
    </div>
    <div class="title">{{data.name}}</div>
    <div class="btn-wrap">
        <ng-container *ngIf="data.name !== 'Izba ekspozycyjno-edukacyjna'">
            <a class="btn" routerLink="/wirtualny-spacer" [queryParams]="{i: data.vr_id.id}">Zobacz</a>
        </ng-container>
        <ng-container *ngIf="data.name == 'Izba ekspozycyjno-edukacyjna'">
            <a class="btn" (click)="togglePopup()">Zobacz</a>
        </ng-container>
    </div>
</div>

<div [className]="izbaPopup ? 'popup izbaPopup active' : 'popup izbaPopup'" *ngIf="izbaPopup">
    <div class="popup-container">
        <div class="close-popup" (click)="togglePopup()">
            <img src="./assets/img/close.svg" alt="Zamknij">
        </div>
        <div class="container popup-pin">
            <div class="column">
                <text-content [innerHTML]="izbaText" *ngIf="izbaText"></text-content>
            </div>
            <div class="column">
                <div class="images">
                    <div class="img">
                        <img src="https://api.cisystaropolskie.pl/images/small/2020-11-20-5JTbFdOGGbkSk3CD02x7u6Qk6isVgH.jpg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>